import axios from "axios";
import { useState, useEffect } from "react";
import DateTimePicker from '../../components/schedule/DateTimePicker.js';
import authService from "../../services/auth.service.js";

const TableBody = (props) => {
const [isEditing, setIsEditing] = useState(10000);
const [tableData, setTableData] = useState(props.tableData)
console.log("props.tableData", props.tableData)
const [context, setContext] = useState(false);
const [xYPosistion, setXyPosistion] = useState({ x: 0, y: 0 });
const showNav = (event) => {
    event.preventDefault();
    setContext(false);
    const positionChange = {
      x: event.pageX,
      y: event.pageY,
    };
    setXyPosistion(positionChange);
    setContext(true);
  };
const hideContext = (event) => {
    setContext(false);
  };
  const [chosen, setChosen] = useState();
  const initMenu = (chosen) => {
    setChosen(chosen);
  };

const [row, setRow] = useState('')
const [draggedIndex, setDraggedIndex] = useState(null);
const [removeDate, setRemoveDate] = useState(props.removeState)
const [selectedRows, setSelectedRows] = useState([]);
const start = (e, index) => {
    let children = Array.from(e.target.parentNode.parentNode.children)
    setRow(e.target)
}
const [droppedData, setDroppedData] = useState(null)

useEffect(()=> {
  
},)

const handleDrop = async(event, index) => {
  event.preventDefault();
  // console.log("onDrop", event, event.dataTransfer, event.dataTransfer.files, event.dataTransfer.types[0], event.dataTransfer.items)
  const data = event.dataTransfer.getData('text/plain')
  // const data = "https://open.spotify.com/track/4esOae7i4rqTbAu9o5Pxco\nhttps://open.spotify.com/track/1LOSGZUypZMbSAfUvsGTff\nhttps://open.spotify.com/track/7nXq1vaZiz7PdbfojpPjW5"
  const tmpTrackUris = data.split("\n")
  const trackUris = tmpTrackUris.map((item)=>{
    return "spotify:track:"+item.split("/").pop()
  })

  let suser = authService.getCurrentUser()
  if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
  let resp = await axios.post('https://scheduler.omarimc.com/server/insertSongs', {playlistId: props.playlistId, trackUris, position:index})
  props.stateChange()
  setDroppedData(data)
}

const dragover = (e) => {
    e.preventDefault();

    let children= Array.from(e.target.parentNode.parentNode.children);

    if(e.target.parentNode)
    {
        if(children.indexOf(e.target.parentNode)>children.indexOf(row))
            e.target.parentNode.after(row);
        else
            e.target.parentNode.before(row);
        setDraggedIndex(children.indexOf(row))
    }
}

const dragEnd = async(e, item) => {
    // playlistId, item.uri
    e.preventDefault()
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    await axios.post('https://scheduler.omarimc.com/server/replaceTracks', {playlistId:props.playlistId, trackURI:item.track.uri, position: draggedIndex})
    setDraggedIndex(null)
}

const handleDoubleClick = (index) => {
    // alert(index)
    setIsEditing(index);
};

const handleBlur = (value) => {
    if(value)
    {
        // PropaneSharp.handle
        props.onStateChange(isEditing, value.toString())
    }
    setIsEditing(10000);
};

const handleChange = (value) => {
    // setText(event.target.value);
};

const handleRowClick = async(event, id, item) => {
    if (event.ctrlKey || event.metaKey) {
      // Ctrl+Click selects/deselects a single row
      if (selectedRows.includes(id)) {
        setSelectedRows(selectedRows.filter(rowId => rowId !== id));
      } else {
        setSelectedRows([...selectedRows, id]);
      }
    } else if (event.shiftKey) {
      // Shift+Click selects a range of rows
      const lastSelectedIndex = selectedRows[selectedRows.length - 1];
      const lastIndex = id;
      const range = Array.from(
        { length: Math.abs(lastIndex - lastSelectedIndex) + 1 },
        (_, index) => Math.min(lastIndex, lastSelectedIndex) + index
      );
      setSelectedRows(range);
    } else {
      // Click without modifier keys selects a single row
      setSelectedRows([id]);
    }
  };

  useEffect(() => {
    const handleBackspace = (event) => {
      if (event.keyCode === 8) {
        let suser = authService.getCurrentUser()
        if(!suser||!authService.tokenVerify())
          window.location.href = '/login'
        // const newData = tableData.filter((row, i) => !selectedRows.some((selectedRow) => selectedRow.track.uri === row.track.uri));
        const newData = tableData.filter((row, i) => {
            if(!selectedRows.includes(i)) return row
        });
        let tracks = []
        selectedRows.map((selectedRow) => {
            let position = tableData[selectedRow].position
            console.log("position", position, tableData[selectedRow].track.uri)
            let tmpObj = {uri: tableData[selectedRow].track.uri, position: tableData[selectedRow].position}
            tracks.push(tableData[selectedRow].position)
        })
        console.log("tracks", tracks, tableData)
        const options = {
            playlistId: props.playlistId,
            tracks:tracks
        }
        axios.post('https://scheduler.omarimc.com/server/removeSongsFromSpotify', options)
        setTableData(newData)
        setSelectedRows([])
      }
    };

    document.addEventListener('keydown', handleBackspace);

    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, [selectedRows]);

    return (
     <tbody style={{backgroundColor:"#151828"}}>
        {
            tableData.length>0&&tableData.map((item, i) => {
            return (
            item.track&&<tr style={{textAlign:"center", marginBottom:10}} draggable="true" onDragStart={start} onDragOver={dragover} id={item.position} onDragEnd={(e)=>dragEnd(e, item)} onClick={event => handleRowClick(event, i, item)} className={selectedRows.includes(i) ? 'selected' : ''} onDrop={(e)=>handleDrop(e, i)}>
                <td>{i+1}</td>
                <td><img src={item.track.album.images[0].url} style={{width:55, height:55}} /></td>
                <td>{item.track.name}</td>
                <td>{item.track.artists[0].name}</td>
                <td>{item.track.hasOwnProperty('album')&&item.track.album.name}</td>
                <td>{item.track.popularity}</td>
                <td>{(new Date(item.added_at)).toGMTString()}</td>
                <td>Click to edit</td>
                <td>
                {
                    isEditing==i && <DateTimePicker  onChange = {handleChange} onBlur = {handleBlur} style={{fontSize:10}}/>                        
                }
                {
                    isEditing!=i&&<div onDoubleClick={()=>handleDoubleClick(i)} >
                    {
                        !props.removeState[i]&&<p>Double-click to edit</p>
                    }
                    {
                        props.removeState[i]&&<p>{props.removeState[i]}</p>
                    }
                    </div>
                }
                </td>                
            </tr>
            )
            })
        }
     </tbody>
    );
   };
   
   export default TableBody;