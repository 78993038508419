import { useEffect, useState } from "react";
import ListBody from "./ListBody";
import ListHead from "./ListHead";
import axios from  'axios'
import './table.css'
import authService from "../../services/auth.service";

let loading = 0;
const List = (props) => {
 const [tableData, setTableData] = useState(props.tableData);
const [removeState, setRemoveState] = useState([])

 const columns = [
  { label: "No", accessor: "no" },
  { label: "Song", accessor: "song" },
  { label: "Name", accessor: "trackName" },
  { label: "ARTIST", accessor: "artist" },
  { label: "PLAYLIST", accessor: "playlistName" },
  { label: "ACCOUNT", accessor: "account" },
  { label: "ALBUM", accessor: "album" },
  { label: "POPULARITY", accessor: "popularity" },
  { label: "DATE ADDED", accessor: "date_added" },
 ];

 useEffect(()=> {
   if(loading == 0){
      loading = 1;
    //   getRemoveState()
   }
 })
 return (
  <>
   <table style={{backgroundColor:"#151828", width:"100%", borderCollapse: "collapse"}} className="mt-2">
    <ListHead columns={columns} style={{backgroundColor:"#151828"}} />
    <ListBody tableData={tableData} playlistId={props.playlistId} style={{backgroundColor:"#151828"}} className= "mt-5"/>
   </table>
  </>
 );
};

export default List;