import Navbar from "../components/navbar/Navbar";
import Profile from "../components/Profile";

export default function UserProfile () {
    return(
        <>
        <Navbar />
        <Profile />
        </>
    )
}