import axios from "axios";
import { useState, useEffect } from "react";
import DateTimePicker from '../schedule/DateTimePicker.js';
import authService from "../../services/auth.service.js";

const ListBody = (props) => {
const [isEditing, setIsEditing] = useState(10000);
const [tableData, setTableData] = useState(props.tableData)
console.log("props.tableData", props.tableData)
const [row, setRow] = useState('')
const [draggedIndex, setDraggedIndex] = useState(null);
const [removeDate, setRemoveDate] = useState(props.removeState)
const [selectedRows, setSelectedRows] = useState([]);
useEffect(()=> {
  
},)

const handleBlur = (value) => {
    if(value)
    {
        // PropaneSharp.handle
        props.onStateChange(isEditing, value.toString())
    }
    setIsEditing(10000);
};

const handleChange = (value) => {
    // setText(event.target.value);
};

const handleRowClick = async(event, id, item) => {
    if (event.ctrlKey || event.metaKey) {
      // Ctrl+Click selects/deselects a single row
      if (selectedRows.includes(id)) {
        setSelectedRows(selectedRows.filter(rowId => rowId !== id));
      } else {
        setSelectedRows([...selectedRows, id]);
      }
    } else if (event.shiftKey) {
      // Shift+Click selects a range of rows
      const lastSelectedIndex = selectedRows[selectedRows.length - 1];
      const lastIndex = id;
      const range = Array.from(
        { length: Math.abs(lastIndex - lastSelectedIndex) + 1 },
        (_, index) => Math.min(lastIndex, lastSelectedIndex) + index
      );
      setSelectedRows(range);
    } else {
      // Click without modifier keys selects a single row
      setSelectedRows([id]);
    }
  };

  useEffect(() => {
    const handleBackspace = (event) => {
      if (event.keyCode === 8) {
        let suser = authService.getCurrentUser()
        if(!suser||!authService.tokenVerify())
          window.location.href = '/login'
        // const newData = tableData.filter((row, i) => !selectedRows.some((selectedRow) => selectedRow.track.uri === row.track.uri));
        const newData = tableData.filter((row, i) => {
            if(!selectedRows.includes(i)) return row
        });
        console.log("selected Rows", selectedRows)
        let tracks = []

        // spotify:track:
        selectedRows.map((selectedRow) => {
            let trackUri = []
            trackUri.push({
              uri: tableData[selectedRow].trackUri
            })
            let tmpObj = {playlistId: tableData[selectedRow].playlistId, trackUri, name: tableData[selectedRow].trackName}
            tracks.push(tmpObj)
        })
        console.log("tracks-------", tracks)
        axios.post('https://scheduler.omarimc.com/server/removeSearchTracks', tracks)
        setTableData(newData)
        setSelectedRows([])
      }
    };

    document.addEventListener('keydown', handleBackspace);

    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, [selectedRows]);

    return (
     <tbody style={{backgroundColor:"#151828"}}>
        {
            tableData.length>0&&tableData.map((item, i) => {
            return (
            <tr style={{textAlign:"center", marginBottom:10}} onClick={event => handleRowClick(event, i, item)} className={selectedRows.includes(i) ? 'selected' : ''} >
                <td>{i+1}</td>
                <td><img src={item.imgUrl} style={{width:55, height:55}} /></td>
                <td>{item.trackName}</td>
                <td>{item.artist}</td>
                <td>{item.playlistName}</td>
                <td>{item.account}</td>
                <td>{item.album}</td>
                <td>{item.popularity}</td>
                <td>{item.addedAt}</td>     
            </tr>
            )
            })
        }
     </tbody>
    );
   };
   
   export default ListBody;