import logo from '../images/logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../components/schedule/Schedule.css'
import NavBar from '../components/navbar/Navbar';
import { useEffect, useState, useRef } from 'react';
import GmailTreeView from '../components/schedule/Tree'
import axios from 'axios';
import { AiOutlineUser, AiOutlineSync } from "react-icons/ai"
import { BsPlusCircleFill } from "react-icons/bs"
import { Link, Route, Switch } from 'react-router-dom';
import Scheduler from '../components/Scheduler';
import PlaylistView from '../components/PlaylistView';
import AddPlaylist from '../components/AddPlaylist';
import { RotatingLines } from 'react-loader-spinner'
import { Routes } from 'react-router';
import authService from '../services/auth.service';
import { WindowOutlined } from '@mui/icons-material';

let isLoading = 0
function Schedule({ code }) {
  let currentUser = authService.getCurrentUser()
  const inputElement = useRef()
  const [playlistOptions, setPlaylistData] = useState(null)
  const [treeData, setTreeData] = useState([])
  const [totalFollowers, setTotalFollowers] = useState(0)
  const [selectedUser, setUser] = useState(null)
  const [schedulerKey, setSchedulerKey]= useState(0)
  const [loadingFlag, setLoading ]= useState(true)
  const [flag, setFlag] = useState(0)
  const [selectedPlaylistId, setPlaylist] = useState('')

  const handleFlagEvent = (flag, value) => {
    setFlag(flag)
    if(flag == 1) setUser(value)
    if(flag == 2) 
    {
      setPlaylist(value)
      // inputElement.current.scrollTo(0,0)
      window.scrollTo(0,0)
    }
  }

  const get_data = async () => {
    // await getPlaylistDataFromSpotify()
    // get_data();
    await initData()
    setLoading(false)
  }
  
  useEffect(() => {
    // if (!token || token == undefined) return;
    // setAccessToken(token)
    // setLoading(1)
    get_data()
  }, []);
  
  const handleUpdateUserFollowers = async(userId) => {
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    setLoading(true)
    let response = await axios.get('https://scheduler.omarimc.com/server/updateUserFollowers/' + userId) 
    console.log("response", response.data)
    setLoading(false)
    if(response.data.hasOwnProperty("error")){
      if(response.data.error == "invalid_grant")
      {
        alert("Please reconnect your account")
        window.location.href = "/accounts"
      }
      else 
      {
        alert("something went wrong!")
        window.location.href = "/schedule"
      }
    }
    else
      window.location.href = "/schedule"
  }

  const initData = async () => {
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    axios.defaults.headers.common['Authorization'] = `Bearer `+ suser.token;

    let response = await axios.post('https://scheduler.omarimc.com/server/getTreeData')
    const treeData = [];
    let tmpPlaylistData = []
    let tmpTotalFollowers = 0;
    for(let tmpPlaylistNode of response.data) {
      for(let tmpPlaylistNodeChild of tmpPlaylistNode.children){
        tmpTotalFollowers += tmpPlaylistNodeChild.followers
        let tmpPlaylistItem = {}
        let tmpLabel = tmpPlaylistNodeChild.name.replace(/&nbsp;/g, " ").replace(/\u00A0/g, " ");
        tmpPlaylistItem.label = tmpLabel+'('+ tmpPlaylistNodeChild.followers+ ')';
        tmpPlaylistItem.value = tmpPlaylistNodeChild.spotifyId;
        tmpPlaylistData.push(tmpPlaylistItem)
      }
    }
    // console.log("---------- tmpPlaylistData ----------", tmpPlaylistData)
    setPlaylistData(tmpPlaylistData)
    setTotalFollowers(tmpTotalFollowers)
    for (let treeNodeData of response.data) {
      let treeNode = { title: '', key: '', children: [] }
      treeNode.title = 
      <div className='row'>
        <div className='col-md-8'>
            <p style={{color:"rgb(126,128,133)", fontSize:18}}>{treeNodeData.title}</p>
        </div>
        <div className="col-md-1" style={{fontSize:16}}>
          <a className="edit-link" onClick={()=>handleFlagEvent(1, {label:treeNodeData.title, value:treeNodeData.key})}
           ><BsPlusCircleFill style={{color:"red"}}/></a>
        </div>
        <div className='col-md-3' style={{alignItems:"center"}}>
          <div style={{marginRight:10}}>
            <p style={{color:"rgb(126,128,133)", fontSize:16, textAlign:"right"}} onClick={()=>handleUpdateUserFollowers(treeNodeData.key)}>{treeNodeData.followers}<AiOutlineSync /></p>
          </div>
        </div>
      </div>
      treeNode.key = treeNodeData.key;
      for (let tmpTreeNodeChildrenObject of treeNodeData.children) {
        let treeNodeChildrenObject = { title: '', key: '', uri:'' }
        treeNodeChildrenObject.followers = <div className='mr-2'><center>
          <AiOutlineUser size={24} style={{color: "rgb(126,128,133)"}}/>
          <p style={{color:"rgb(126,128,133)"}}>{tmpTreeNodeChildrenObject.followers}</p>
          </center>
          </div>
        treeNodeChildrenObject.title = <div style={{ width: "100%", display: "block" }}>
          <div className="row">
            <div className="col col-md-12" style={{ alignSelf: "center", textAlign:"center", fontSize:16 }}>
              <div className='row'>
                <div className='col-md-2'>
                  <img src={tmpTreeNodeChildrenObject.images} alt="not found" width={50} />
                </div>
                <div className='col-md-10 mr-2' style={{alignSelf:"center"}}>
                <a className="edit-link" onClick={() => handleFlagEvent(2,tmpTreeNodeChildrenObject.spotifyId)}
                >
                  <p style={{ color: "rgb(126,128,133)", textDecoration: "none" }}>{tmpTreeNodeChildrenObject.name}</p>
                </a>
                </div>
              </div>
            </div>
            <hr className="hr" />
          </div>
        </div>
        treeNodeChildrenObject.key = tmpTreeNodeChildrenObject.spotifyId
        treeNodeChildrenObject.uri = tmpTreeNodeChildrenObject.uri
        treeNode.children.push(treeNodeChildrenObject)
      }
      treeData.push(treeNode)
    }
    setTreeData(treeData)
  }

  const handleScheduleSubmit = () => {
    setSchedulerKey(schedulerKey + 1)
  }
  return (
    <div className="App">
      <NavBar />
      <div className='myContainer'>
        {
          loadingFlag?(
          <div className='row' style={{marginTop:300, marginBottom:300}}><center>
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
            </center></div>
          ):(
            <div className='row' ref={inputElement}>
              <div className='col col-md-4 ml-5' style={{ paddingLeft: 15 }}>
                <div className='row'>
                  <div className='col col-md-10 offset-md-1' style={{ padding: 5, textAlign: 'center' }}>
                    <div className='logo_img'>
                      <a href='/'>
                        <img src={logo} alt='logo' className='logo_img'/>
                      </a>
                    </div>
                    <p className='rounded-pill bg-danger mt-5' style={{ height: 40, color: 'white', paddingTop: 9 }}>YOU HAVE {totalFollowers} FOLLOWER(S).</p>
                    <p style={{textAlign:"left", color:"rgb(126,128,133)"}}> {currentUser.username}/ {treeData.length} ACCOUNTS/ {totalFollowers} FOLLOWERS</p>
                    <hr className='hr' />
                  </div>
                </div>
                <div className='col col-md-10 offset-md-1 mt-3 ml-5'>
                  {/* <Tree style={{ width: '100%', backgroundColor: '#0c0e18', color: 'rgb(126, 128, 133)', fontSize: 18 }} treeData={treeData} /> */}
                  <GmailTreeView style={{ width: '100%',fontSize: 18, color:"rgb(126,128,133)" }} treeData={treeData}  />
                </div>
              </div>
              {
                treeData.length>0&&flag==2&&<PlaylistView playlistId = {selectedPlaylistId} key={selectedPlaylistId}/>
              }
              {
                treeData.length>0&&flag==0&&<Scheduler playlistOptions={playlistOptions}  onSubmit = {handleScheduleSubmit} key = {schedulerKey}/>
              }
              {
                treeData.length>0&&flag==1&&<AddPlaylist treeData={treeData} user={selectedUser} key={selectedUser.value}/>
              }
            </div>
           ) 
        }
      </div>
    </div>
  );
}

export default Schedule;
