import check from '../../images/check.png'
import MultiSelect from '../../components/schedule/MultiSelect'
import DateTimePicker from '../../components/schedule/DateTimePicker.js';
import { useEffect, useState } from 'react';
import axios from 'axios';
import authService from '../../services/auth.service';


const folderData = [
  { value: 'folder1', label: 'folder1' },
  { value: 'folder2', label: 'folder2' },
  { value: 'folder3', label: 'folder3' },
  { value: 'folder4', label: 'folder4' },
  { value: 'folder5', label: 'folder5' },
]

function Scheduler(props) {
  const [scheduleData, setscheduleData] = useState({
    trackUri: [],
    playlistIds: [],
    position: 1,
    scheduledDate: '',
    removeDate: ''
  });

  const handleBlur = () => {

  }
  const handleTrackUriChange = (event) => {
    let tmpArray = [];
    const trackUrl = event.target.value
    const trackId = trackUrl.split('/').pop()
    tmpArray.push(trackId)
    setscheduleData({
      ...scheduleData,
      trackUri: tmpArray
    });
  }

  const handleMultiChange = (value) => {
    setscheduleData({
      ...scheduleData,
      playlistIds: value
    });
  }

  const handleScheduleDateChange = (value) => {
    setscheduleData({
      ...scheduleData,
      scheduledDate: value
    });
  }

  const handleRemoveDateChange = (value) => {
    setscheduleData({
      ...scheduleData,
      removeDate: value
    });
  }
  const handleChange = (event) => {
    setscheduleData({
      ...scheduleData,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = () => {
    // event.preventDefault()
    let suser = authService.getCurrentUser();
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    if(scheduleData.trackUri.length>0)
      // axios.post('https://scheduler.omarimc.com/server/schedule', { scheduleData })
      axios.post('https://scheduler.omarimc.com/server/schedule', { scheduleData })
        .then((response) => {
          // if(response.status == 401){
          //   alert("please reconnect your account")
          //   location.href = "/accounts"
          // }
          if(response.data.hasOwnProperty("error")){
            if(response.data.error == "invalid_grant")
            {
              alert("Please reconnect your account")
              window.location.href = "/accounts"
            }
            else {
              alert("something went wrong")
              window.location.href = "/schedule"
            }
          }
          else {
            
            console.log(response.data.scheduleResult, '--- schedule result ---', scheduleData)
            // location.href = "/schedule"
            let failedList = [];
            response.data.scheduleResult.map((item, i)=>{
              if(item == 0){
                console.log("failed playlists", scheduleData.playlistIds[i].label)
                failedList.push(scheduleData.playlistIds[i].label)
              }
            })
            let alertString = scheduleData.playlistIds.length + ' / ' + (scheduleData.playlistIds.length - failedList.length) + "  playlists schedule success\n"
            alertString += "\n" + failedList.length + " playlists schedule failed\n"
            failedList.map((failedItem) => {
              alertString += '\n - '+failedItem
            })
            alert(alertString)
            setscheduleData({})
            props.onSubmit()
          }
      })
    else
      alert("something went wrong!")
  }
    return (
        <div className='col col-md-8 col-sm-8 col-xs-8'>
            <h2 className='color-white' style={{ color: 'white', textTransform: 'uppercase', fontWeight: 'bold' }}>Schedule
              <span className='schedule_history'><a className='schedule_history' href='/schedule/history'>SCHEDULE HISTORY</a></span>
            </h2>
            <hr className='hr' />
            <div>
              <div className='row'>
                <div className='form-group col col-md-10 col-md-offset-2 mt-2'>
                  <label className='control-label label-field mb-1' for='spotifytop-track'>Track id / Spotify track URI / URL</label>
                  <div className='form-group field-spotifytop-track required has-error'>
                    <input type='text' id='spotifytop-track' className='form-control form-field' name='trackUri' required onChange={handleTrackUriChange} />
                  </div>
                </div>
                <div className='form-group col-md-4 mt-4'>
                  <span id='followers-count'></span>
                  <label className='control-label label-field' for='spotifytop-playlists'>PlayLists</label>
                  <div className='form-group form-field'>
                    <MultiSelect options={props.playlistOptions} onChange={handleMultiChange} />
                  </div>
                </div>
                <div className='col-md-4 offset-md-1 form-group mt-4'>
                  <span id='followers-count'></span>
                  <label className='control-label label-field' for='spotifytop-playlists'>Folder</label>
                  <div className='form-group form-field'>
                    <MultiSelect options={folderData} />
                  </div>
                </div>
                <div className='clearfix'></div>
                <div className='col-md-offset-1 col-md-4 form-group mt-4'>
                  <label className='control-label check label-field' for='spotifytop-position'>Add to Range</label>
                  <img src={check} className='hidden img-checked-range' width='22' />
                  <label for='range'></label>
                </div>
                <div className='col-md-10 col-md-offset-1 form-group'>
                  <div className='row mt-3'>
                    <div className='col-md-6 no-padd-left form-group'>
                      <label className='control-label label-field mb-1' for='spotifytop-position'>Position</label>
                      <div className='form-group field-spotifytop-position has-success'>
                        <input type='number' id='spotifytop-position' className='form-control form-field' name='position' min='1' onChange={handleChange}  placeholder='default : 1'/>
                        <div className='help-block'></div>
                      </div>
                    </div>
                    <div className='col-md-6 form-group end-pos hidden'>
                      <label className='control-label label-field mb-1' for='spotifytop-position'>Position End</label>
                      <div className='form-group field-spotifytop-position_end'>
                        <input type='number' id='spotifytop-position_end' className='form-control form-field' name='SpotifyTop[position_end]' />
                        <div className='help-block'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='clearfix'></div>
                <div className='col-md-10 col-md-offset-1 form-group'>
                  <div className='row mt-5'>
                    <div className='col-md-6 no-padd-left form-group'>
                      <label className='control-label label-field mb-1' for='spotifytop-date'>Date</label>
                      <DateTimePicker onChange={handleScheduleDateChange} onBlur={handleBlur} style={{width:"100%"}}/>
                    </div>
                    <div className='col-md-6 form-group remove_date'>
                      <label className='control-label label-field mb-1' for='spotifytop-position'>Remove Track(s) after x days</label>
                      <DateTimePicker onChange={handleRemoveDateChange} onBlur={handleBlur}/>
                    </div>
                  </div>
                </div>
                <div className='col-md-offset-1 col-md-8 form-group'>
                  <label className='control-label label-field check mt-2' for='spotifytop-position'>Remove Track(s) after x
                    days</label>
                  <img src={check} className='img-checked' width='22' />
                  <label for='remove_track'></label>
                </div>
                <div className='col-md-8 offset-md-1 form-group text-center mt-5'>
                  <input type='button' className='btn btn-danger' value='SUBMIT' onClick = {()=>onSubmit()}/>
                </div>
              </div>
            </div>
          </div>
    );
}

export default Scheduler