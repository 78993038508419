import { format } from "date-fns";

export const COLUMNS = [
  {
    Header: "Playlist Name",
    accessor: "playlistName",
  },
  {
    Header: "Account Name",
    accessor: "userName",
  },
  {
    Header: "Followers",
    accessor: "followers",
  },
  {
    Header: "Followers\n Change",
    accessor: "followersChange",
  },
  {
    Header: "Followers\n Change Before",
    accessor: "followersChangeBefore",    
  },
  {
    Header: "Total",
    accessor: "total",
  }
  // {
  //   Header: "Add Competetion",
  //   accessor: "addCompetition"
  // }
];