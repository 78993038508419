import NavBar from '../components/navbar/Navbar';
import { loginUrl } from "../spotify";
import useAuth from '../useAuth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './account.css'
import { RotatingLines } from 'react-loader-spinner'
import authService from '../services/auth.service';
const code = new URLSearchParams(window.location.search).get('code')

function Account() {
  const token = useAuth(code)
  let [accounts, setAccounts] = useState([])
  const [isLoading, setLoadingFlag] = useState(false)
  const getPlaylists = async(token) => {
    try{
      let response = await axios.post('https://scheduler.omarimc.com/server/myPlaylists', { accessToken: token })
    //   setPlaylistData(response.data)
    //   let tempArray = await response.data.map(item=>{
    //     return {value: item.id, label: item.name}
    //   })
    //   setPlaylistData(tempArray)
        window.location.href = '/schedule'
    }catch(err){
      console.log(err)
    }
  }

  const getAccounts = async() => {
    try{
      console.log("getAccounts started")
      let suser = authService.getCurrentUser()
      let flag= authService.tokenVerify()
      console.log("flag", flag)
      if(!suser||!authService.tokenVerify())
        window.location.href = '/login'
      axios.defaults.headers.common['Authorization'] = `Bearer `+ suser.token;
      console.log("tokent", suser.token)

      let response = await axios.get('https://scheduler.omarimc.com/server/accounts')
      setAccounts(response.data)
    }catch(err) {
        console.log("getAccounts error", err)
    }
  }
  const get_data = async() =>{
    // await getAccounts()
    // await getPlaylists(token)
    // await getTreeData()
  }

  const deleteAccount = async(_id) => {
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    let response = await axios.post('https://scheduler.omarimc.com/server/deleteAccount', { _id })
    window.location.reload(true);
  }

  useEffect(() => {
    if(code)
      setLoadingFlag(true)
  }, [code])

  useEffect(() => {
    getAccounts();
    if (!token || token == undefined) return;
    // setAccessToken(token)
    setLoadingFlag(false)
    get_data()
  }, [token]);
    return (
        <div>        
        {/* {code ? (<p>accounts - done</p>):(<p>accounts - loading</p>)} */}
          <NavBar />
          {
          isLoading?(
          <div className='row' style={{marginTop:300, marginBottom:300}}><center>
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
            </center></div>
          ):(
          <div className='row'>
            <div className='col col-md-7 offset-md-1 mt-5 ml-5 text-center'>
                <h2>Accounts</h2>
                <ul className="styled-list">
                    {
                        accounts.map((account, index) => (
                            <li key={index} style={{fontSize : 22}}>
                              <div className='row'>
                                <div className='col col-md-10'>
                                  {account.displayName}
                                </div>
                                <div className='col col-md-2'>
                                  <div className='btn btn-danger' onClick={()=> deleteAccount(account._id)}>DELETE</div>
                                </div>
                              </div>
                            </li>
                        ))
                    }

                </ul>
            </div>
            <div className='col col-md-3'>
                <a className="schedule_history btn btn-info mt-5" href={loginUrl} style={{float: "right", right: 120, marginRight:"5%", color:"white", cursor:"pointer"}}>Add Account</a>
            </div>
          </div>
          )}
        </div>
    )
}

export default Account;