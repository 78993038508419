import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeNode } from 'antd/es/tree-select';
import { useState } from 'react';
import './Tree.css'

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}      
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};
//0c0e18
export default function GmailTreeView({ treeData }) {
  const [context, setContext] = useState('');
  const [xYPosistion, setXyPosistion] = useState({ x: 0, y: 0 });
  const [clipData, setClipData] = useState('')
  const showNav = (event, index) => {
    event.preventDefault();
    setContext('');
    const positionChange = {
      x: event.clientX,
      y: event.clientY,
    };
    console.log("event",event, positionChange, index)
      setXyPosistion(positionChange);
      setContext(index);
      // setTreeKey(treeKey+1)
  };
  const hideContext = (event) => {
      setContext('');
    };
  const [chosen, setChosen] = useState();
  const initMenu = (chosen) => {
    if(navigator.clipboard)
    {
      navigator.clipboard.writeText(chosen)
      .then(function(){
        console.log("Copying to clipboard was successful!")
      })
    }
    else{
      var textArea = document.createElement("textarea")
      textArea.value = chosen
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
  
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
  
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'success' : 'failed';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
  
        document.body.removeChild(textArea);
    }
    setChosen(chosen);
    setContext('')
  };
  const handleCopy = () => {
    alert('Data copied to Clipboard')
  }
  return (
    <TreeView
      aria-label="gmail"
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, width: "100%", backgroundColor: "#0c0e18", color:"rgb(126,128,133)", fontSize:18 }}
      onClick={hideContext}
    >
        {
            treeData.map((treeNode, i) => (
               <StyledTreeItem nodeId={treeNode.key} labelText={treeNode.title} labelIcon={SupervisorAccountIcon} color="rgb(126,128,133)">
                  <>
                    {treeNode.children.map((treeNodeChildren) => (
                      <>
                        <StyledTreeItem
                        nodeId={treeNodeChildren.key}
                        labelText={treeNodeChildren.title}
                        labelIcon={SupervisorAccountIcon}
                        labelInfo={treeNodeChildren.followers}
                        color="#1a73e8"
                        bgColor="#e8f0fe"
                        onContextMenu={(e)=> showNav(e, treeNodeChildren.key)}
                        onClick={hideContext}
                        />
                        {
                          // trackUrl.split('/').pop()
                          context==treeNodeChildren.key && (
                            <div
                              style={{ top: xYPosistion.y, left: xYPosistion.x }}
                              className="rightClick rounded" onBlur={hideContext}
                            >                            
                                <div className="menuElement" onClick={() => initMenu("https://open.spotify.com/user/"+ treeNode.key + "/playlist/"+treeNodeChildren.uri.split(':').pop())}>
                                  Copy Spotify Url
                                </div>
                                <div className="menuElement" onClick={() => initMenu("spotify:user:"+treeNode.key+":playlist:"+treeNodeChildren.uri.split(':').pop())}>
                                  Copy Spotify Uri
                                </div>
                            </div>
                          )
                        }
                        </>
                    ))}
                  </>
                </StyledTreeItem>
                
            ))
        }
    </TreeView>
  );
}