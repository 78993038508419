import { Link } from 'react-router-dom'
import backgroundImage from '../images/bg.jpg'
import authService from '../services/auth.service'

export default function Home(){
    let currentUser = authService.getCurrentUser()
    const logout = () => {
        authService.logout()
        window.location.href = '/'
    }
    return(
        <div style={{width:"100%", height:"100vh", position:"relative"}}>            
            <img src = { backgroundImage } style={{width:"100%", height:"100%"}} />
            {
                !currentUser?(
                    <div style={{position:'absolute', top:"20px", fontSize:16}} className='col col-md-2 offset-md-10'>
                        <Link to='/login'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Login</button></Link>
                        <Link to='/signup'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Register</button></Link>
                    </div>
                ):(
                    <div style={{position:'absolute', top:"20px", fontSize:16, right:0}}>
                        <center>
                        {currentUser.role=='admin'&&<Link to='/users'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Users</button></Link>}
                        <Link to='/dashboard'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Dashboard</button></Link>
                        <Link to='/schedule'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Schedule</button></Link>
                        <Link to='/statistic'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Statistic</button></Link>
                        <Link to='/accounts'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Accounts</button></Link>
                        <Link to='/profile'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}}>Profile</button></Link>
                        <Link to='/schedule'><button type="button" className="btn btn-link" style={{color:"white", textDecoration:"none", fontSize:20}} onClick={logout}>Logout</button></Link>
                        </center>
                    </div>
                )

            }
            <div style={{top:"40%", left:"20%", position:"absolute"}}>
                <h1 style={{color:"white"}}>Manage your <span style={{color: "#ed265d"}}>Playlists</span></h1>
            </div>
            <div style = {{position: "absolute", top:"80%", right:"10%", color:"white"}}>
                <h4>support: help.scheduleromarimc@gmail.com</h4>
            </div>
        </div>

    )
}