import axios from "axios";
import { useEffect, useState } from "react"
import Navbar from "../components/navbar/Navbar";

let flag = false;
export default function Users(){
    const [users, setUsers] = useState([])
    const getUsers = async() =>{
        let resp = await axios.get('https://scheduler.omarimc.com/server/users')
        console.log(resp.data)
        setUsers(resp.data.users)
    }
    useEffect(() => {
        if(flag == false)
        {
            flag = true
            getUsers()
        }
    })

    const handleAllow = async(userId, value) => {
        let response = await axios.post('https://scheduler.omarimc.com/server/setUserStatus', {userId, value})
        let tmpUsers = users.map((user)=>{
            if(user._id == userId)
                user.status = value
            return user
        })
        // console.log("tmpUsers", tmpUsers)
        setUsers(tmpUsers)
    }

    return(
        <>
        <Navbar />
        <center className="mt-5">
            <h1>Users</h1>
        </center>
        <table id="dtBasicExample" className="table-striped table-bordered table-sm col-md-10 offset-md-1 mt-2 text-center">
            <thead>
                <tr>
                <th class="th-sm">No

                </th>
                <th class="th-sm">User Name

                </th>
                <th class="th-sm">Email

                </th>
                <th class="th-sm">Action

                </th>
                </tr>
            </thead>
            <tbody>
                {
                    users.length&&users.map((user, index)=>(
                        <tr>
                            <td>{index+1}</td>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.status==1?<button type="button" className="btn btn-danger" onClick={()=>handleAllow(user._id, 0)}>Deny</button>:<button className="btn btn-success" type="button" onClick={()=>handleAllow(user._id, 1)}>Allow</button>}</td>
                        </tr>
                    ))
                }
            </tbody>
            </table>
            </>
    )
}