import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import '../schedule/Schedule.css'
import NavBar from '../navbar/Navbar';
import MultiSelect from '../schedule/MultiSelect'
import { useEffect, useState, useRef } from 'react';
import DateTimePicker from '../schedule/DateTimePicker.js';
import GmailTreeView from '../schedule/Tree'
// import testData from "../testData.json"
import {BiEdit} from 'react-icons/bi'
import Table from '../Table'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { AiOutlineUser, AiOutlineSync } from "react-icons/ai"
import {BsSearch} from "react-icons/bs"
import { RotatingLines } from 'react-loader-spinner'
import authService from '../../services/auth.service';
import './PlaylistView.css'

function PlaylistView({playlistId}) {
  // props.match.params.userId
  const [playlistOptions, setPlaylistData] = useState(null)
  const [currentPlaylist, setPlaylist] = useState({})
  const [treeData, setTreeData] = useState([])
  const [selectedUser, setUser] = useState(null)
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [tableState, setTableState] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tableKey, setTableKey] = useState(0)
  const stateChange = () =>{
    console.log("root stateChange")
    getPlaylist(playlistId)
    setTableState(!tableState)
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null)
  const [playlistName, setPlaylistName] = useState(currentPlaylist.name)
  const [playlistDescription, setPlaylistDescription] = useState(currentPlaylist.description)
  const fileInputRef = useRef(null);


  const handleFileInputChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0])
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    if(event.target.name=="name")
      setPlaylistName(event.target.value)
    if(event.target.name=="description")
      setPlaylistDescription(event.target.value)
  }
  const handleSubmit = () => {
    if(image&&playlistName&&playlistDescription)
    {
      let formData = new FormData();
      formData.append('image', file);

      formData.append('name', playlistName)
      formData.append('description', playlistDescription)
      formData.append('owner', currentPlaylist.owner.id)
      formData.append('spotifyId', currentPlaylist.id)
      // formData.append('currentPlaylist', {...currentPlaylist})
      setOpen(false)
      setLoading(true)
      let suser = authService.getCurrentUser();
      if(!suser||!authService.tokenVerify())
        window.location.href = '/login' 
      axios.post('https://scheduler.omarimc.com/server/upload', formData)
        .then(response => {
          setLoading(false)
          if(response.data.hasOwnProperty("error")){
            if(response.data.error == "invalid_grant")
            {
              alert("Please reconnect your account")
              window.location.href = "/accounts"
            }
            else {
              alert("something went wrong")
              window.location.href = "/schedule"
            }
          }
        else{
          alert("Playlist Detail changed successfully")
          location.href="/schedule"
        }
        })
        .catch(error => {
          console.error(error);
        });
    }
    else
      alert("something went wrong!")
  };

  const getPlaylist = async(playlistId) => {
    // console.log("getPlaylist", playlistId)
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    setLoading(true)
    let tmpPlaylist = await axios.get('https://scheduler.omarimc.com/server/playlist/' + playlistId)
    if(tmpPlaylist.data.hasOwnProperty("error")){
      if(tmpPlaylist.data.error == "invalid_grant")
      {
        alert("Please reconnect your account")
        window.location.href = "/accounts"
      }
      else {
        alert("something went wrong")
        window.location.href = "/schedule"
      }
    }
    else{
        const bj1 = {...tmpPlaylist.data};
        setPlaylist(bj1)
        setPlaylistName(tmpPlaylist.data.name)
        setPlaylistDescription(tmpPlaylist.data.description)
        console.log("tmpPlaylist",tmpPlaylist)
        if(tmpPlaylist.data.images.length>0){
          setImage(tmpPlaylist.data.images[0].url)
        }
        let tmpTableData = []
        tmpPlaylist.data.tracks.items.map((item, i) => {
          tmpTableData.push({...item, position: i})
        })
        console.log("tmptable data", tmpTableData)
        setTableData(tmpTableData)
        setLoading(false)
    }
    // console.log("getPlaylist end", playlistId)

  }

  useEffect(() => {
    currentPlaylist.length && console.log("currentPlaylist", currentPlaylist.followers.total)
  }, [currentPlaylist]);

  const get_data = async() => {
    await getPlaylist(playlistId)
    // await getPlaylistDataFromSpotify()
    // await getPlaylists()
    // get_data();
  }

  useEffect(() => {
    // setPlaylist(testData)
    // if (!token || token == undefined) return;
    // setAccessToken(token)
    get_data()
  }, []);

  const handleSearch = (e) => {
    // console.log("search value", e.target.value)
    let filteredArray = []
    currentPlaylist.tracks.items.map((item, i) => {
      if(item.track.name.toLowerCase().includes(e.target.value)){
        filteredArray.push({...item, position: i})
      }
    })
    setTableData(filteredArray)
    setTableKey(tableKey+1)
  } 
  return (
    <div className='col col-md-8 col-sm-8 col-xs-8'>
      <div className='row'>          
      {
        isLoading&&
          <div className='row' style={{marginTop:300, marginBottom:300}}><center>
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
            </center></div>
      }
        {
          !isLoading&&Object.keys(currentPlaylist).length > 0 && (
            
            <div className='col col-md-12 col-sm-12 col-xs-12'>
              <div className='row'>
                <div className='col-md-3'>
                  {currentPlaylist.images.length>0&&<img src = {currentPlaylist.images[0].url} size={20} className='rounded-circle' style={{width:200}}/>}
                </div>
                <div className='col-md-7'>
                  <div className='row'>
                    <div className='col col-md-7'>
                        <p className='mt-3'>{currentPlaylist.name}</p><br />
                        <div className='btn btn-danger mt-2 rounded-pill' onClick={()=>handleClickOpen()}>Edit <BiEdit /></div><br />
                        <p className='mt-4'>FOLLOWERS: {currentPlaylist.followers.total}</p>
                      </div>
                    <div className='col-md-5'>
                      <p className='mt-3'>{currentPlaylist.owner.display_name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row '>
                <div className="col-md-3 offset-md-8 form-control form-field " style={{width:"inherit"}}>
                    <input type="text" onChange={handleSearch} style={{marginRight:5, color:"white", backgroundColor:"inherit", border:"none", zIndex:2}} /><BsSearch />
                </div>
                <div className='col-md-1'>
                  <AiOutlineSync size={24} style={{float:"right", marginRight:30, cursor:"pointer"}} onClick={()=>getPlaylist(playlistId)}/>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Table tableData = {tableData} playlistId={currentPlaylist.id} key={tableKey} stateChange={stateChange}/>
                  {/* {
                    console.log("currentPlaylist !!!!", currentPlaylist)
                  } */}
                </div>
              </div>
            </div>
          )
        }
     </div>
     {/* modal */}
      {
        Object.keys(currentPlaylist).length > 0 && (
          <div>            
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Edit Playlist</DialogTitle>
              <form enctype="multipart/form-data"> 
                <DialogContent style={{width:600}}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Playlist Name"
                    name = "name"
                    type="text"
                    value={playlistName}
                    fullWidth
                    variant="standard" onChange={handleChange}
                    required
                  />
                  <div className='row mt-3'>
                    <div className='col col-md-4'>
                      <div className="row">
                        <div className="col col-md-12">
                          <input type="file" ref={fileInputRef} onChange={handleFileInputChange}  style={{display:"none"}} required/>
                        </div>
                        <div className="col col-md-12">
                          {
                            image&&<img src={image} alt="Preview" className="preview" style={{width:"100%"}}/>
                          }
                          {
                            currentPlaylist.images.length>0&&!image&&<img src={currentPlaylist.images[0].url} alt="Preview" className="preview" style={{width:"100%"}}/>
                          }
                          {
                            !currentPlaylist.images.length&&!image&&<div className="preview" style={{width:"100%"}}></div>
                          }
                          <center className='mt-3'>
                            <input type = "button" className="btn btn-danger " onClick={handleUploadButtonClick} value = "Choose image" />
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className='col col-md-8'>
                      <p>Description</p>
                      <textarea style={{width:"100%", height:"80%"}} name="description" onChange={handleChange} required>{playlistDescription}</textarea>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>handleClose()}>Close</Button>
                  <input type='button' className='btn btn-danger' value="Submit" onClick={handleSubmit}/>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        )
      }
    </div>
  );
}

export default PlaylistView;
