import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.css'

const DateTimePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.onChange(date);
  };

  const handleBlur = () => {
    console.log("child blur")
    props.onBlur(selectedDate)
  }

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      // onCalendarClose={handleBlur}  
      onBlur={handleBlur}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      dateFormat="MMMM d, yyyy h:mm aa"
      placeholderText="Select a date and time"
      className='form-control form-field w-100'
    />
  );
};

export default DateTimePicker;