import React, { Component, useState } from "react";
import logo from '../../images/logo.png'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default function Register() {

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [successful, setSuccessful] = useState(false)
    const [message, setMessage] = useState('')

//   onChangeUsername(e) {
//     this.setState({
//       username: e.target.value
//     });
//   }
    const onChangeUsername = (e) => {
        setUsername(e.target.value)
    }

//   onChangeEmail(e) {
//     this.setState({
//       email: e.target.value
//     });
//   }
    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }

//   onChangePassword(e) {
//     this.setState({
//       password: e.target.value
//     });
//   }
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

  const handleRegister = (e) => {
    e.preventDefault();
      setMessage("")
      setSuccessful(false)
      AuthService.register(
          username,
          email,
          password
      ).then(
        response => {
          console.log("response", response)
        setSuccessful(true)
        setMessage(response.data)
        alert("Your account created successfully!\nPlease wait until you account is aproved")
        window.location.href = '/login'
      },
      error => {
          const resMessage =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
  
          setSuccessful(true)
          setMessage(resMessage)
          alert(resMessage)
          window.location.reload();
      }
      );
  }

    return (
      <div style={{ display:"flex", height:"100vh", justifyContent:"center", alignItems:"center"}}>
        <div className="col-md-4 p-5">
          <div className="card card-container p-3">
          <a href="/" style={{alignSelf:"center", width:"60%"}}><img
              src={logo}
              alt="profile-img"
              className="profile-img-card mb-2 mt-5 w-100"
            /></a>

            <form
              className="mt-3"
              onSubmit={handleRegister}
              // ref={c => {
              //   this.form = c;
              // }}
            >
              {!successful && (
                <div>
                  <div className="form-group" style={{ margin:"4%", textAlign:"left"}}>
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      name="username"
                      value={username}
                      onChange={onChangeUsername}
                      required
                    />
                  </div>

                  <div className="form-group" style={{ margin:"4%", marginTop:0, textAlign:"left"}}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      required
                    />
                  </div>

                  <div className="form-group" style={{ margin:"4%", marginTop:0, textAlign:"left"}}>
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      required
                    />
                  </div>
                  <div className="col col-md-4 offset-md-4 mt-3">
                    <center style={{maxHeight:70}}><button className="btn btn-primary btn-block mt-3 mb-5">Sign Up</button></center>
                  </div>
                </div>
              )}

              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful
                        ? "alert alert-success text-center"
                        : "alert alert-danger text-center"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              {/* <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              /> */}
            </form>

            <div className="ml-5 mr-5 mb-5" style={{textAlign:"center", display:"inline"}}>
              <hr style={{marginLeft:"5%", marginRight:"5%"}}/>
              Already have an account? <Link to='/login'><button type= "button" className="btn btn-link" style={{textDecoration:"none"}}>Log in</button></Link>
            </div>
          </div>
        </div>
      </div>
    );
}
