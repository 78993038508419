import CanvasJSReact from '@canvasjs/react-stockcharts';
import axios from "axios";
import MultiSelect from './schedule/MultiSelect.js'

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

export default function Chart(props) {
    return (
        <div className='row mt-3' style={{ height: "50%" }}>
            <CanvasJSStockChart containerProps={props.containerProps} options={props.options}/>
        </div>
    )
}