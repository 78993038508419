import logo from '../../images/logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import '../schedule/Schedule.css'
import NavBar from '../navbar/Navbar';
import MultiSelect from '../schedule/MultiSelect'
import { useEffect, useState } from 'react';
import GmailTreeView from '../schedule/Tree'
import axios from 'axios';
import { AiOutlineUser, AiOutlineSync } from "react-icons/ai"
import { BsPlusCircleFill } from "react-icons/bs"
import { useLocation } from 'react-router-dom';
import authService from '../../services/auth.service';

function AddPlaylist(props) {
  const location = useLocation()
  const currentUser = props.user
  const [userOptions, setUsersData] = useState(null)
  const [treeData, setTreeData] = useState([])
  const [totalFollowers, setTotalFollowers] = useState(0)
  const [selectedUser, setUser] = useState(null)
  const [createPlaylistData, setNewPlaylistData] = useState({
    name:'',
    users:[]
  })

  const handleMultiChange = (value) => {
    setNewPlaylistData({
      ...createPlaylistData,
      users: value
    });
  }

  const handleChange = (event) => {
    setNewPlaylistData({
      ...createPlaylistData,
      [event.target.name]: event.target.value
    });
  };

  const getUsers = async() => {
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    let response = await axios.post('https://scheduler.omarimc.com/server/getTreeData')
    let tempArray = await response.data.map(item => {
        return { value: item.key, label: item.title }
    })
    setUsersData(tempArray)
  }
  const get_data = async () => {
    let tmpArray = [];
    tmpArray.push(props.user)
    setNewPlaylistData({
      ...createPlaylistData,
      users: tmpArray
    });
    await getUsers()
  }

  useEffect(() => {
    // if (!token || token == undefined) return;
    // setAccessToken(token)
    get_data()
  }, []);

  const onSubmit = (event) => {
    event.preventDefault()
    let suser = authService.getCurrentUser()
    if(!suser||!authService.tokenVerify())
      window.location.href = '/login'
    console.log("createPlaylistData", createPlaylistData)
    axios.post('https://scheduler.omarimc.com/server/playlist', { createPlaylistData })
      .then((response) => {
        if(response.data.hasOwnProperty("error")){
          if(response.data.error == "invalid_grant")
          {
            alert("Please reconnect your account")
            window.location.href = "/accounts"
          }
          else {
            alert("something went wrong")
            window.location.href = "/schedule"
          }
        }
        else {
          console.log('create playlist response', response);
          alert("playlist created successfully!")
          window.location.href = "/schedule"
        }
      })
  }


  return (
 
          <div className='col col-md-8 col-sm-8 col-xs-8'>
            <form onSubmit={onSubmit}>
              <h2><b style={{color: "white"}}>ADD PLAYLIST</b></h2>
              <hr style={{height: 1,background: "#fff", display: "block"}}></hr>
              <div className='col-md-6 offset-md-3' style={{ marginTop: "5%" }}>
                  <label className='control-label label-field' for='playlistName'>Name<span style={{color:"red"}}>*</span></label>
                  <div className='form-group field-playlistName required has-error'>
                  <input type='text' id='playlistName' className='form-control form-field' name='name' required onChange={handleChange} />
                  </div>
              </div>
              <div className='col-md-6 offset-md-3' style={{ marginTop: "5%" }}>
                  <label className='control-label label-field' for='spotifytop-playlists'>Users</label>
                  <div className='form-group form-field'>
                  <MultiSelect options={userOptions} onChange={handleMultiChange} user = {currentUser}/>
                  </div>
              </div>
              <div className='col-md-8 offset-md-1 form-group text-center mt-5'>
                <input type='submit' className='btn btn-danger mt-2' value='SUBMIT' />
              </div>
            </form>
          </div>
  );
}

export default AddPlaylist;
