import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import '../schedule/Schedule.css'
import NavBar from '../navbar/Navbar';
import MultiSelect from '../schedule/MultiSelect'
import { useEffect, useState, useRef } from 'react';
import DateTimePicker from '../schedule/DateTimePicker.js';
import GmailTreeView from '../schedule/Tree'
// import testData from "../testData.json"
import { BiEdit } from 'react-icons/bi'
import List from './List'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { AiOutlineUser, AiOutlineSync } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import { RotatingLines } from 'react-loader-spinner'
import authService from '../../services/auth.service';
// import './PlaylistView.css'

function SearchList(props) {
  // props.match.params.userId
  const [playlistOptions, setPlaylistData] = useState(null)
  const [currentPlaylist, setPlaylist] = useState({})
  const [treeData, setTreeData] = useState([])
  const [selectedUser, setUser] = useState(null)
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [tableState, setTableState] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tableKey, setTableKey] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const get_data = async () => {
    // await getSearchDatas(playlistId)
    // await getPlaylistDataFromSpotify()
    // await getPlaylists()
    // get_data();
  }

  useEffect(() => {
    // setPlaylist(testData)
    // if (!token || token == undefined) return;
    // setAccessToken(token)
    get_data()
  }, []);

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const handleSearch = async(e) => {
    if(e.key === 'Enter')
    {
      setLoading(true)
      let response = await axios.get('https://scheduler.omarimc.com/server/trackSearchData?key='+searchValue)
      setTableData(response.data.tableData)
      setLoading(false)
      setTableKey(tableKey+1)
      console.log(response.data)
    }
  }

  return (
    <div className='col col-md-8 col-sm-8 col-xs-8'>
      <div className='row'>
        {
          isLoading &&
          <div className='row' style={{ marginTop: 300, marginBottom: 300 }}><center>
            <RotatingLines
              strokeColor="green"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </center></div>
        }
        {
          !isLoading && (

            <div className='col col-md-12 col-sm-12 col-xs-12'>
              
              <div className='row '>
                <div className="col-md-3 offset-md-8 form-control form-field " style={{width:"inherit"}}>
                  <input type="text" onChange={handleChange} onKeyPress={handleSearch} value={searchValue} style={{marginRight:5, color:"white", backgroundColor:"inherit", border:"none", zIndex:2}} /><BsSearch />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <List tableData={tableData} key={tableKey} />
                  {/* {
                    console.log("currentPlaylist !!!!", currentPlaylist)
                  } */}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default SearchList;
