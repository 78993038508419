import React, { useState } from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBBtn,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBProgress,
    MDBProgressBar,
    MDBIcon,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios'

import authService from '../services/auth.service';

export default function Profile() {
    let suser = authService.getCurrentUser()
    const [flag, setFlag] = useState(false)
    const [password, setPassword] = useState('')
    const [password_, setPassword_] = useState('')

    const handleClickOpen = () => {
        setFlag(true)
    }

    const handleChange = (e) => {
        e.preventDefault();
        console.log("event", e.target, e.target.value)
        if (e.target.name == "password")
            setPassword(e.target.value)
        if (e.target.name == "password_")
            setPassword_(e.target.value)
        console.log("password, password_", password, password_)
    }

    const handleClose = () => {
        setFlag(false)
    }

    const handleSubmit = async() => {
        let suser = authService.getCurrentUser();
        if(!suser||!authService.tokenVerify())
            window.location.href = '/login'
        console.log("handleSubmit", password, password_)
        if (password == password_) {
            setFlag(false)
            let resp = await axios.post("https://scheduler.omarimc.com/server//changeUserPassword", { user: suser, password })
            if (resp.status == '200') alert('Password changed successfuly!')
            console.log("RUH", resp)
            window.location.href = '/login'
        }
        else {
            alert("password doesn't match")
        }
    }
    return (
        <>
            <section style={{ backgroundColor: '#eee' }}>
                <MDBContainer className="py-5">
                    <MDBRow className='align-items-center justify-content-center'>
                        <MDBCol>
                            <MDBCard className="mb-4">
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Username</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{suser.username}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Email</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{suser.email}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Role</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{suser.role}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Status</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{suser.status == 1 ? 'Active' : 'Disenabled'}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Password</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9" className="d-flex justify-content-between">
                                            <MDBCardText className="text-muted">{suser.password}</MDBCardText>
                                            <Button className='btn btn-success' onClick={handleClickOpen}>Change your password</Button>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>

                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
            <div >
                <Dialog open={flag} onClose={handleClose}>
                    <div style={{width:570}} >
                    <DialogTitle>Change Password</DialogTitle>
                    <form>
                        <DialogContent >
                            <section style={{ backgroundColor: '#eee' }}>
                                <MDBContainer style={{ padding: 0 }}>

                                    <MDBRow>
                                        <MDBCol lg="12">
                                            <MDBCard>
                                                <MDBCardBody className='justify-content-center'>
                                                    <MDBRow className='align-items-center'>
                                                        <MDBCol sm="4">
                                                            <MDBCardText>Original password</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="8">
                                                            <MDBCardText className="text-muted">{suser.password}</MDBCardText>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <hr />
                                                    <MDBRow className='align-items-center'>
                                                        <MDBCol sm="4">
                                                            <MDBCardText>New Password</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="8">
                                                            <MDBCardText className="text-muted"><input className='w-100' type="password" name="password" onChange={(e) => handleChange(e)} /></MDBCardText>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <hr />
                                                    <MDBRow className='align-items-center'>
                                                        <MDBCol sm="4">
                                                            <MDBCardText>Password Confirm</MDBCardText>
                                                        </MDBCol>
                                                        <MDBCol sm="8">
                                                            <MDBCardText className="text-muted"><input className='w-100' type="password" name="password_" onChange={(e) => handleChange(e)} /></MDBCardText>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCardBody>
                                            </MDBCard>

                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>
                        </DialogContent>
                        <DialogActions className='justify-content-center'>
                            <Button className='mr-5' onClick={handleClose}>Close</Button>
                            <input type='button' className='btn btn-danger ml-5' value="Submit" onClick={handleSubmit} />
                        </DialogActions>
                    </form>
                    </div>
                </Dialog>
            </div>
        </>
    );
}