import React, { Component, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../images/logo.png'
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import AuthService from "../../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function Login(props) {
  
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

  const onChangeUsername = (e) => {
    setUsername(e.target.value)
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = (e) => {
    e.preventDefault();

    // this.setState({
    //   message: "",
    //   loading: true
    // });

    // this.form.validateAll();

      AuthService.login(username, password).then(
        () => {
          // this.props.router.navigate("/profile");
          // window.location.reload();
          window.location.href = "/accounts"
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

            setLoading(false)
            setMessage(resMessage)
        }
      );
  }

    return (
      <div style={{ display:"flex", height:"100vh", justifyContent:"center", alignItems:"center"}}>
        <div className="col-md-4 pl-3 p-5">
          <div className="card card-container mt-3 p-3">
            <a href="/" style={{alignSelf:"center", width:"60%"}}><img
              src={logo}
              alt="profile-img"
              className="profile-img-card mb-3 mt-5 w-100"
              
            /></a>

            <form className="mt-3"
              onSubmit={handleLogin}
            >
              <div className="form-group" style={{ margin:"5%", textAlign:"left"}}>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control mt-2"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  // validations={[required]}
                />
              </div>

              <div className="form-group" style={{ margin:"5%", textAlign:"left"}}>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control mt-2"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  // validations={[required]}
                />
              </div>
              <div className="form-group" style={{ margin:"5%", marginBottom:0, textAlign:"left"}}>
                <center style={{maxHeight:70}}>
                  <button
                  className="btn btn-primary btn-block mt-2 mb-5"
                  disabled={loading}
                  >
                      {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                  </button>
                </center>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger text-center" role="alert">
                    {message}
                  </div>
                </div>
              )}

            </form>

            <div className="ml-5 mr-5 mb-5" style={{textAlign:"center", display:"inline"}}>
              <hr style={{marginLeft:"5%", marginRight:"5%"}}/>
              Don't have an account? <Link to='/signup'><button type= "button" className="btn btn-link" style={{textDecoration:"none"}}>Sign Up</button></Link>
            </div>
          </div>
        </div>
      </div>
    );
}
