import { useState, useEffect } from "react";
import axios from "axios";
import authService from "./services/auth.service";

export default function useAuth(code) {
    const [accessToken, setAccessToken] = useState();
  
    const getToken = async() => {
      let suser = authService.getCurrentUser();
      if(suser){
        try{
          axios.defaults.headers.common['Authorization'] = `Bearer `+ suser.token;
          let resp =  await axios.post("https://scheduler.omarimc.com/server/login", { code })
          setAccessToken(resp.data.accessToken)
          window.location.href = "/accounts"
        }catch(err){
          console.log(err)
        }
      }
      else{
        window.location
      }
    }

    useEffect(() => {
      if(code!=null||code!=undefined)
        getToken()
    }, []);
  
    return accessToken
  }