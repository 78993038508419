import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const MultiSelect = (props) => {
  const defaultValues = []
  defaultValues.push(props.user)
  const [selectedOptions, setSelectedOptions] = useState(defaultValues);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    props.onChange(selected)
  };

  useEffect(()=> {
    
    if(props.user){
      setSelectedOptions(defaultValues)
      props.onChange(defaultValues)
    }
  },[props.user])

  return (
    <Select
      isMulti
      isSearchable
      options={props.options}
      defaultValue={defaultValues}
      onChange={handleChange}
      placeholder=""
      className='form-field'
      styles={{
        control: (provided) => ({
            ...provided,
            borderRadius: '4px',
            borderColor: '#ccc',
            boxShadow: 'none',
            backgroundColor:"#0f111e",
            overflow: "auto",
            maxHeight: 200
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f2f2f2',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            color: '#999',
            ':hover': {
              color: '#666',
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#f2f2f2' : 'white',
            color: state.isSelected ? '#333' : '#666',
            ':hover': {
              backgroundColor: '#f2f2f2',
              color: '#333',
            },
          }),
          input: (provided) => ({
            ...provided,
            color: "rgb(126, 128, 133)"
          })
      }}
    />
  );
};

export default MultiSelect;