import { useEffect, useMemo, useState } from "react";
import { COLUMNS } from "./column";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CanvasJSReact from '@canvasjs/react-stockcharts';
import axios from "axios";
import MultiSelect from './schedule/MultiSelect1.js'
import Chart from "./Chart";
import './StatisticTable.css'

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const StatisticTable = (props) => {
  // this.state = { dataPoints: [], dataPoints1:[], isLoaded: false };
  const [dataPoints, setDatapoints] = useState([])
  const [dataPoints1, setDatapoints1] = useState([])
  const [selectPlaylistData, setSelectPlaylistData] = useState([])
  const [selectedPlaylists, setSelectedPlaylists] = useState([])
  const [chartData, setChartData] = useState([])
  const [colors, setColors] = useState(['#3576a8', 'red', 'green', 'yellow', 'rosybrown'])
  const [chartKey, setChartKey] = useState(0)

  const columns = useMemo(() => COLUMNS, []); // memoize before adding to useTable hook
  const data = useMemo(() => [...props.mockData], [props.mockData]);
  console.log("data", data)
  const [tableData, setTableData] = useState(props.mockData)
  const [cMenuPosition, setCMenuePosition] = useState({ x: 0, y: 0})
  const [contextId, setContextId] = useState('')
  const defaultColumn = {
    // Set your default column properties here
    // For example, you can set a default width or alignment
    width: 100,
    align: 'left',
  };

  const [options, setOptions] = useState({
    theme: "light2",
    charts: [{
      axisX: {
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
          valueFormatString: "MMM DD YYYY"
        }
      },
      axisY: {
        title: "Playlist Followers",
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
          valueFormatString: "$#,###.##"
        }
      },
      toolTip: {
        shared: true
      },
      data: [
        {
          name: "followers()",
          type: "spline",
          color: "red",
          yValueFormatString: "$#,###.##",
          xValueFormatString: "MMM DD YYYY",
          dataPoints: dataPoints[0]
        }
      ]
    }],
    navigator: {
      slider: {
        minimum: new Date("2017-05-01"),
        maximum: new Date("2018-07-01")
      }
    }
  });
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setSelectedPlaylists([])
    setOpen(false)
  }

  const handleGraph = async(playlistId, playlistName) => {
    console.log("playlistId", playlistId)
    let tmpDatapoints = []
    let filteredArray = props.statistic.filter(obj => obj.playlistId === playlistId)
    tmpDatapoints.push(filteredArray.map((obj) => {
        let gmtDate = new Date(obj.date)
      return {
        x: new Date(new Date(gmtDate.getTime() + gmtDate.getTimezoneOffset() * 60000)),
        y: Number(obj.followers)
      }
    }))
    // console.log('tmpDatapoints',filteredArray, tmpDatapoints)    
    setDatapoints(tmpDatapoints)
        
    console.log("datapoints : ", dataPoints)
    let tmpChartData = []
    // selectedPlaylists.map((playlist, index) => {
    tmpChartData.push({
      name: playlistName,
      type: "spline",
      color: colors[0],
      yValueFormatString: "$#,###.##",
      xValueFormatString: "MMM DD YYYY",
      dataPoints: tmpDatapoints[0]
    })

    let tmpOptions = { ...options }
    tmpOptions.charts[0].data = [...tmpChartData]
    console.log('tmpOptions', tmpOptions)
    setOptions(tmpOptions)
    setOpen(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize, state: { pageIndex, pageSize, globalFilter } } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 20 }
      },
      useGlobalFilter,
      useSortBy, // this adds sorting feature to the table instance
      usePagination,
    );

  const handleChange = (value) => {
    console.log("select palylist change data", value)
    setSelectedPlaylists(value)
  }

  const handleShow = async() => {
    console.log("handleshow", options)
    let tmpDatapoints = []
    selectedPlaylists.map((playlistItem) => {
      let filteredArray = props.statistic.filter(obj => obj.playlistId === playlistItem.value)
      tmpDatapoints.push(filteredArray.map((obj) => {
        let gmtDate = new Date(obj.date)
        return {
          x: new Date(new Date(gmtDate.getTime() + gmtDate.getTimezoneOffset() * 60000)),
          y: Number(obj.followers)
        }
      }))
    })
    console.log("tmpDatapoints", tmpDatapoints, selectedPlaylists.length)
    setDatapoints([...dataPoints, ...tmpDatapoints])
        
    console.log("datapoints-handleshow : ", dataPoints)

    let tmpChartData = []
    selectedPlaylists.map((playlist, index) => {
      tmpChartData.push({
        name: playlist.label,
        type: "spline",
        color: colors[(index+1)%5],
        yValueFormatString: "$#,###.##",
        xValueFormatString: "MMM DD YYYY",
        dataPoints: tmpDatapoints[index]
      })
    })
    console.log('tmpChartData', tmpChartData)
    let tmpOptions = { ...options }
    tmpOptions.charts[0].data = [tmpOptions.charts[0].data[0],...tmpChartData]
    console.log('tmpOptions-handleshow', tmpOptions)
    setOptions(tmpOptions)

    // setOptions([...dataPoints, ...tmpChartData])
    setChartKey(chartKey+1)
  }

  const get_data = async () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OTcxZGI4ODBkNWNiZmE4ZjI4YjEwNCIsImlhdCI6MTY4ODQzOTkyOCwiZXhwIjoxNjg4NDQzNTI4fQ.lblchgizYdjz6G6-ZoajOQeiRfkurff5LOeEsIAna8Y';
    let response = await axios.get('https://scheduler.omarimc.com/server/playlists')

    // console.log("playlists for followers", response.data)
    let tmpSelectPlaylists = []
    tmpSelectPlaylists = response.data.map((item) => {
      return { label: item.name, value: item.spotifyId }
    })
    // console.log("sel playlists data", tmpSelectPlaylists)
    setSelectPlaylistData(tmpSelectPlaylists)
  }

  useEffect(() => {
    get_data()
  }, [])

  useEffect(()=>{
    const _handleClick = (event) => {
      // const { visible } = this.state;
      // const wasOutside = !(event.target.contains === this.root);
      
      // if (wasOutside && visible) this.setState({ visible: false, });
      var targetElement = event.target;

      if(targetElement.classList.contains('contextMenu--option')) {
        // alert("click event")
      }

      setContextId('')
    };

    document.addEventListener('click', _handleClick);

    return () => {
      document.removeEventListener('click', _handleClick)
    }
  }, [])
  // const handle

  const containerProps = {
    width: "100%",
    height: "450px",
    margin: "auto"
  };

  const handleSelectChange = async(e) => {
    console.log(e.target.value)
    props.onChange(e.target.value)
    // setTableData(resp.data.tableData)
  }

  const handleContextMenue = (e, playlistId) => {
    e.preventDefault();
    const positionChange = {
      x: e.clientX,
      y: e.clientY,
    };
    console.log("event",e, positionChange)
    setCMenuePosition(positionChange);
    setContextId(playlistId)
  }

  const [chosen, setChosen] = useState();
  const initMenu = (chosen) => {
    if(navigator.clipboard)
    {
      navigator.clipboard.writeText(chosen)
      .then(function(){
        console.log("Copying to clipboard was successful!")
      })
    }
    else{
      var textArea = document.createElement("textarea")
      textArea.value = chosen
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
  
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
  
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'success' : 'failed';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
  
        document.body.removeChild(textArea);
    }
    setChosen(chosen);
    setContextId('')
  };

  return (
    <>
      <div className="row w-100 d-flex justify-content-center mb-5 mt-2">
        <div className="dropdown col-md-3">
          Period: {" "}
          <select name="period" id="#period" className= "form-control color-gray" style={{width:200}} onChange={handleSelectChange} value={props.selValue}>
              <option value="0">Choose</option>
              <option value="1">24 Hours</option>
              <option value="7">7 Days</option>
              <option value="28">28 Days</option>
          </select>
        </div>
        <div className="col col-md-3 offset-md-3">
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </div>
      </div>
      {/* apply the table props */}
      <table {...getTableProps()} style={{margin:"auto", fontSize:18}}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="mr-2" style={{marginRight:3}}>{column.render("Header")}<span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span></th>
                  ))
                }
                <th >Action</th>
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map((row, i) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, i) => {
                      console.log("row", row)
                      if(i == 0)
                        return <td {...cell.getCellProps()} onContextMenu={(e) =>handleContextMenue(e, row.original.playlistId)}>{cell.render("Cell")}
                           {
                            row.original.playlistId == contextId &&<div
                              style={{ top: cMenuPosition.y, left: cMenuPosition.x }}
                              className="rightClick rounded contextMenu" 
                            >                            
                                <div className="menuElement contextMenu--option" style={{cursor:"pointer", margin:5}} onClick={() => initMenu("https://open.spotify.com/user/"+ row.original.userId + "/playlist/"+row.original.playlistUri.split(':').pop())}>
                                  Copy Spotify Url
                                </div>
                                <div className="menuElement contextMenu--option" style={{cursor:"pointer", margin:5}} onClick={() => initMenu("spotify:user:"+row.original.userId+":playlist:"+row.original.playlistUri.split(':').pop())}>
                                  Copy Spotify Uri
                                </div>
                            </div>
                          }
                        </td>;
                      // Apply the cell props
                      else
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })
                  }
                  <td><button className="btn btn-light" onClick={() => handleGraph(row.original.playlistId, row.original.playlistName)}>Graph</button></td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
      <div className="pagination" style={{ marginTop: "1rem", justifyContent:"center" }}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-light">
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-light">
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-light">
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="btn btn-light">
          {">>"}
        </button>{" "}
        <span style={{alignSelf: "center"}}>
          {"  "}Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span style={{alignSelf: "center"}}>
          |  Go to page:{"  "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            // className="form-control"
            style={{ width: "100px", borderRadius:8 }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="form-control"
          style={{width:100}}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>
        <Dialog open={open} onClose={handleClose} className="my-dialog">
          <div style={{ width: "100%", display:"block"}}>
            <DialogTitle>Playlist Control: See your Followers Graphic</DialogTitle>
            <DialogContent>
              <div className='row mt-3' style={{ height: "50%" }}>
                <Chart containerProps={containerProps} options={options} key={chartKey}/>
              </div>
              <div className="row" style={{}}>
                {
                  selectedPlaylists.length && selectedPlaylists.map((item, i) => (
                    <p className="mt-2 ml-2"><span style={{color:colors[i+1]}}> --- </span>{item.label}</p>
                  ))
                }
              </div>
            </DialogContent>
            <DialogActions>
              <div className='form-group mb-5' style={{ width: "100%", left: 20 }}>
                <div className="row">
                  <div className="col col-md-8 justify-content-around">
                    <MultiSelect options={selectPlaylistData} onChange={handleChange}/>
                  </div>
                  <div className="col col-md-3 offset-md-1 align-self-center justify-content-around">
                    <input type='button' className='btn btn-danger mr-3' value="Show" onClick={() => handleShow()} />
                    <Button className="ml-3" onClick={() => handleClose()}>Close</Button>
                  </div>
                </div>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default StatisticTable;