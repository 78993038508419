import React, { useEffect, useState } from "react";
import StatisticTable from "../components/StatisticTable";
import axios from "axios";
import './statistic.css'
import { RotatingLines } from 'react-loader-spinner'
import Navbar from "../components/navbar/Navbar";

export default function Statistic() {
  // const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [tableKey, setTableKey] = useState(0)
  const [selValue, setSelValue] = useState(0)
  const [statisticData, setStatisticData] = useState([])
  const [loadingFlag, setLoading ]= useState(true)


  const getGMTTime = (time) => {
    const gmtTime = new Date(time.getTime() + time.getTimezoneOffset() * 60000);
    return gmtTime;
  }

  const getTableData = async(period) => {    
    setLoading(true)
    let resp = await axios.get('https://scheduler.omarimc.com/server/statistic/table?period='+period)
    console.log("table Data", resp.data.tableData)
    setLoading(false)
    setTableData(resp.data.tableData)
    setSelValue(period)
    setTableKey(tableKey+1)
  }

  const handleSelChange = (period) =>{
    console.log("props.onChange")
    getTableData(period)
  }
  const fetchData = async () => {
    // const { data } = await axios("https://giftea.github.io/proj-data/mock.json").catch((err) => console.log(err));
    // setData(data);
    let tmpChartData = []
    setLoading(true)
    let resp = await axios.get('https://scheduler.omarimc.com/server/statistic/chart')

    setStatisticData(resp.data.statistics)
    setLoading(false)
    // let currentDate = getGMTTime(new Date())
    // let tableData = []
    // statistic.map((item) => {
    //   let someDay = getGMTTime(new Date(item.date))
    //   if (currentDate.getUTCFullYear() === someDay.getUTCFullYear() &&
    //     currentDate.getUTCMonth() === someDay.getUTCMonth() &&
    //     currentDate.getUTCDay() === someDay.getUTCDay()) {
    //     tableData.push(item)
    //   }
    // })
    // let tData = []
    // currentDate.setDate(currentDate.getDate()-1)
    // console.log("currentDate", currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDay())
    // tableData.map((item) => {
    //   statistic.map((sItem) => {
    //     let someDay = getGMTTime(new Date(sItem.date))
    //     console.log("some day", someDay.getUTCDay())
    //     if (currentDate.getUTCFullYear() === someDay.getUTCFullYear() &&
    //       currentDate.getUTCMonth() === someDay.getUTCMonth() &&
    //       currentDate.getUTCDay() === someDay.getUTCDay()&&item.playlistId === sItem.playlistId) {
    //       tData.push({ ...item, followersChange: sItem.followers })
    //     }
    //   })
    // })
    // console.log("table data", tableData, tData, statistic.length)
    // console.log("App.js",statistic, tmpChartData)
    getTableData(0)
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    
    <div style={{backgroundColor: "#333", color:"white"}}>
        {
            !loadingFlag?(
                <>
                    <Navbar />
                    <StatisticTable mockData={tableData} statistic={statisticData} onChange={handleSelChange} key={tableKey} selValue={selValue}/>
                </>
            ):(
                <div className='row d-flex align-items-center' style={{height: "100vh"}}><center>
                    <RotatingLines
                    strokeColor="green"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                    />
                    </center>
                </div>
            )
        }
    </div>

  );
}

