const authEndpoint = "https://accounts.spotify.com/authorize";
const redirectUri = "https://scheduler.omarimc.com/accounts/";
// const redirectUri = "http://localhost:8120/accounts/";
const clientId = "5981e97c027b4d76abc585ad301eb4a6";

const scopes = [
  "streaming",
  "user-read-email",
  "user-read-private",
  "ugc-image-upload",
  "playlist-read-private",
  "playlist-modify-public",
  "playlist-modify-private"
];

export const loginUrl = `${authEndpoint}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes.join(
  "%20"
)}&show_dialog=true`;

// loginUrl = "https://accounts.spotify.com/authorize?client_id=YourClientId&response_type=code&redirect_uri=https://localhost:3000/&scope=streaming%20user-read-email%20user-read-private"