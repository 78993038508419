import { useEffect, useState } from 'react'
import { NavLink, BrowserRouter as Router, useHistory} from 'react-router-dom'
// import { ReactComponent as Hamburger } from './favicon.ico'
import { ReactComponent as Brand } from '../../images/logo.svg'
import './Navbar.css'
import axios from 'axios'
import authService from '../../services/auth.service'

const Navbar = () => {
  const [loginRes, setLoginRes] = useState(0)
  const history = useHistory();

  const [showNavbar, setShowNavbar] = useState(false)
  // const [currentUser, setCurrentUser] = useState({})
  let currentUser = authService.getCurrentUser();
  console.log("current user", currentUser)

  // useEffect(()=>{
  //   let suser = authService.getCurrentUser()
  //   if(suser)
  //     setCurrentUser(suser)
  // })
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const goToSchedule = () => {
    window.location.href = "/schedule"
  }

  const handleLogin = () => {
    axios.get('http://localhost:4000/users/login')
            .then(res => {
              setLoginRes(res.data); 
            })  
            .catch((error) => {
            console.log(error);
            })  
  }

  const logout = () => {
    authService.logout();
  }

  return (
    // <Router>
      <nav className="navbar">
        {/* <RawHtmlDialog htmlContent={loginRes} /> */}
        <div className="NavbarContainer">
          <div className="logo">
            <Brand />
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            ooo
          </div>
          <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <ul>              
              {/* <li>
                <NavLink to="/">Dashboard</NavLink>
              </li> */}
              {currentUser.role == 'admin'&&<li>
                <NavLink to="/users">USERS</NavLink>
              </li>}
              <li>
                <NavLink to="/dashboard">DASHBOARD</NavLink>
              </li>
              <li onClick={goToSchedule} style={{color:"white", cursor:"pointer"}} className='schedule'>
                {/* <NavLink to="/schedule">SCHEDULE</NavLink> */}
                SCHEDULE
              </li>
              <li>
                <NavLink to="/statistic">STATISTICS</NavLink>
              </li>
              <li>
                <NavLink to="/accounts">ACCOUNTS</NavLink>
              </li>
              <li>
                <NavLink to="/profile">PROFILE</NavLink>
              </li>
              <li onClick={logout} style={{color:"white", cursor:"pointer"}} className='schedule'>
                LOGOUT
                {/* <div className='btn btn-primary' onClick={handleLogin}>LOGIN</div> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    // </Router>
  )
}

export default Navbar