import logo from './images/logo.svg'
import check from './images/check.png'
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './components/schedule/Schedule.css'
import NavBar from './components/navbar/Navbar';
import MultiSelect from './components/schedule/MultiSelect'
import { useEffect, useState } from 'react';
import DateTimePicker from './components/schedule/DateTimePicker.js';
// import { Tree } from 'antd'
import Schedule from './pages/schedule.js';
import Account from './pages/account.js';
import Home from './components/Home'
import useAuth from "./useAuth";
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Login from './components/Auth/Login'
import Register from './components/Auth/Register';
import Users from './pages/users';
import UserProfile from './pages/Profile';
import Statistic from './pages/statistic';
import Dashboard from './pages/Dashboard';

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path='/login' exact component = {Login} />
        <Route path='/signup' exact component= {Register} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="/accounts" exact component={Account} />
        <Route path="/users" exact component={Users} />
        <Route path='/profile' exact component={UserProfile} />
        <Route path='/statistic' exact component={Statistic} />
        {/* <Route
          exact
          path="/playlist/:playlistId"
          component={(props) => <PlaylistView {...props} />}
        /> */}
      </Switch>
      {/* {code ? (<p>done</p>):(<p>loading</p>)} */}
    </Router>
  );
}

export default App;
